<template>
  <div>
    <vue-slider v-model="allocationValue" :max="parseInt(balance)" />
    <div class="d-flex align-items-center">
      <span class="mr-1 mt-1">R$</span>
      <b-form-input
        id="allocation-value"
        v-model="allocationValue"
        name="allocation-value"
        placeholder="$0.00"
        class="mt-1"
      />
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";

export default {
  components: {
    VueSlider,
  },
  props: {
    balance: Number,
  },
  data() {
    return {
      allocationValue: 0,
    };
  },
  watch: {
    allocationValue: function (newVal) {
      this.$emit("allocationValue", newVal);
    },
    balance: function (newVal) {
      if (this.allocationValue > newVal)
        this.allocationValue = parseInt(newVal);
    },
  },
};
</script>