<template>
  <div>
    <!-- basic modal -->
    <b-modal
      id="modal-transfer-wallet"
      :title="$i18n.t('Fazer transferência')"
      hide-footer
      no-close-on-backdrop
      centered
    >
      <div class="mb-1">
        <div class="mb-1">
          {{ $t("Para qual usuário você deseja transferir?") }}
        </div>
        <b-form-group>
          <b-form-input
            id="user-to"
            v-model="userTo"
            name="login-email"
            :placeholder="$i18n.t('Digite o nome do usuário')"
            :state="userFound"
          />
        </b-form-group>
      </div>
      <div class="text-center mb-2" v-if="userFound">
        <div class="mb-1">{{ $t("Usuário encontrado") }}:</div>
        <div>
          <b-avatar
            button
            variant="primary"
            :src="avatar"
            :text="avatarText(userFoundData.fullname)"
            size="lg"
          />
          <div>{{ userFoundData.fullname }}</div>
        </div>
      </div>
      <div>
        {{ $t("Quanto do seu saldo disponível você deseja transferir?") }}
      </div>
      <slider
        :balance="returnUser.balance"
        class="my-1"
        @allocationValue="(val) => (allocationValue = val)"
      />
      <hr />
      <alert
        :text="textAlert"
        :color="colorAlert"
        :showAlert="showAlert"
        @changeVariableAlert="(val) => (showAlert = val)"
      />
      <div class="d-flex justify-content-center mt-1">
        <b-button
          variant="primary"
          class="w-100 mr-1"
          :disabled="!userFound || loading"
          @click="executeTransfer"
        >
          {{ $t("Transferir") }}
        </b-button>
        <b-button
          variant="outline-primary"
          class="w-100"
          @click="() => $bvModal.hide('modal-transfer-wallet')"
        >
          {{ $t("Sair") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, VBModal, BAlert } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import { BFormRadioGroup, BFormGroup } from "bootstrap-vue";
import Slider from "./Slider.vue";
import { debounce } from "debounce";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BFormRadioGroup,
    BFormGroup,
    Slider,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    openModal: Boolean,
  },
  computed: mapGetters(["returnUser"]),
  watch: {
    openModal: function (newVal) {
      if (newVal == true) {
        this.$bvModal.show("modal-transfer-wallet");
      }
      this.$emit("changeVariable");
    },
    userTo: debounce(function (newVal) {
      this.$store
        .dispatch("searchUser", newVal)
        .then((resp) => {
          this.userFoundData = resp;
          this.userFound = true;
          this.avatar = resp.avatar
            ? `${process.env.VUE_APP_API_URL}/avatar/${resp.avatar}`
            : null;
        })
        .catch((err) => {
          this.userFoundData = {};
          this.userFound = false;
        });
    }, 1000),
  },
  data() {
    return {
      showAlert: false,
      textAlert: "",
      colorAlert: "warning",
      userTo: "",
      allocationValue: 0,
      userFound: null,
      userFoundData: {},
      avatar: null,
      loading: false,
    };
  },
  methods: {
    executeTransfer() {
      if (!this.loading) {
        this.loading = true;
        const data = {
          value: this.allocationValue,
          to: this.userFoundData._id,
        };
        this.$store
          .dispatch("userCreateTransfer", data)
          .then(() => {
            this.showAlert = true;
            this.textAlert = this.$i18n.t(
              "Transferência realizada com sucesso!"
            );
            this.colorAlert = "success";
            this.$store.dispatch("recoverUserData");
            this.$emit("refreshTransferList");
            setTimeout(() => {
              this.$bvModal.hide("modal-transfer-wallet");
              this.loading = false;
            }, 3000);
          })
          .catch((err) => {
            this.loading = false;
            this.showAlert = true;
            this.textAlert = err.message;
            this.colorAlert = "danger";
          });
      }
    },
  },
  setup() {
    avatarText;

    return {
      avatarText,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-slider.scss";
</style>
